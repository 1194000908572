<template>
  <div class="auth-wrapper auth-v1 p-0">
    <div class="auth-inner p-0">
      <b-card>
        <b-overlay :show="loading">
          <h2 class="px-2 mt-1">Organisatie Registreren</h2>
        <form-wizard
          :color="color"
          :title="null"
          :subtitle="null"
          shape="square"
          :finish-button-text="`${organisation.shortName} toevoegen`"
          back-button-text="Vorige"
          next-button-text="Volgende"
          class="mb-3"
          @on-complete="formSubmitted"
        >
        <tab-content title="Beheerders account" :before-change="checkAuth">
            <div v-if="$store.state.AppActiveUser" class="text-center w-100 py-3">
                <b-avatar
                    size="200px"
                    variant="light-primary"
                    :text="getInitials($store.state.AppActiveUser.displayName)"
                    :src="$store.state.AppActiveUser.profilePic ? $store.state.AppActiveUser.profilePic.large : ''"
                />
                <p class="mt-3">Je bent aangemeld als</p>
                <h1>{{$store.state.AppActiveUser.displayName}}</h1>
                <b-button variant="outline-primary" @click="changeAccount">Ander account</b-button>
            </div>
            <div class="d-flex justify-content-center" v-else>
                <login class="w-100" nextPage="/aansluiten" />
            </div>
        </tab-content>
          <tab-content title="Algemene Gegevens" :before-change="validationGeneralForm">
            <b-row>
              <!-- Field: Fullname -->
              <b-col>
                <h3>Algemene gegevens van je organisatie</h3>
                <b-form-group label="Volledige naam" label-for="fullname">
                  <b-form-input
                    id="fullname"
                    :class="$v.organisation.fullName.$error ? 'is-invalid' : ''"
                    v-model="organisation.fullName"
                  />
                </b-form-group>
                <b-form-group label="Weergave naam (deze naam zullen we op de meeste plaatsen in Tapp gebruiken)" label-for="shortname">
                  <b-form-input
                    id="shortname"
                    :class="
                      $v.organisation.shortName.$error ? 'is-invalid' : ''
                    "
                    v-model="organisation.shortName"
                  />
                </b-form-group>
                <b-form-group label="Website" label-for="website">
                  <b-form-input
                    id="website"
                    :class="$v.organisation.website.$error ? 'is-invalid' : ''"
                    v-model="organisation.website"
                  />
                </b-form-group>
                <b-form-group label="E-mail" label-for="mainEmail">
                  <b-form-input
                    id="mainEmail"
                    :class="
                      $v.organisation.mainEmail.$error ? 'is-invalid' : ''
                    "
                    v-model="organisation.mainEmail"
                  />
                </b-form-group>
                <h3>Adres van je organisatie</h3>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Straat">
                      <b-form-input
                        class="form-control"
                        v-model="organisation.address.street"
                        :class="
                          $v.organisation.address.street.$error
                            ? 'is-invalid'
                            : ''
                        "
                        placeholder="Straat"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="Nr.">
                      <b-form-input
                        class="form-control"
                        v-model="organisation.address.housenumber"
                        :class="
                          $v.organisation.address.housenumber.$error
                            ? 'is-invalid'
                            : ''
                        "
                        placeholder="Nr."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="Bus">
                      <b-form-input
                        class="form-control"
                        v-model="organisation.address.addition"
                        :class="
                          $v.organisation.address.addition.$error
                            ? 'is-invalid'
                            : ''
                        "
                        placeholder="Bus"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Postcode">
                      <b-form-input
                        class="form-control"
                        v-model="organisation.address.postal"
                        :class="
                          $v.organisation.address.postal.$error
                            ? 'is-invalid'
                            : ''
                        "
                        placeholder="Postcode"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Plaats">
                      <b-form-input
                        class="form-control"
                        v-model="organisation.address.city"
                        :class="
                          $v.organisation.address.city.$error
                            ? 'is-invalid'
                            : ''
                        "
                        placeholder="Plaats"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </tab-content>
          <tab-content title="Extra informatie" :before-change="validationExtraForm">
            <b-row>
              <b-col>
                <h3>Extra informatie over je organisatie</h3>
                <b-form-group label="Soort organisatie">
                  <v-select
                    class="mb-1"
                    v-model="organisation.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Zoeken..."
                    :options="types"
                    :clearable="false"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Start werkjaar">
                      <b-row>
                        <b-col cols="3">
                          <b-form-group label="Dag">
                            <b-form-input
                              v-model="organisation.start.day"
                              min="1"
                              :max="max[organisation.start.month - 1]"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="9">
                          <b-form-group label="Maand">
                            <v-select
                              class="mb-1"
                              v-model="organisation.start.month"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              placeholder="Zoeken..."
                              :options="months"
                              :reduce="(item) => item.value"
                              :clearable="false"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Einde werkjaar">
                      <b-row>
                        <b-col cols="3">
                          <b-form-group label="Dag">
                            <b-form-input
                              v-model="organisation.end.day"
                              min="1"
                              :max="max[organisation.end.month - 1]"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="9">
                          <b-form-group label="Maand">
                            <v-select
                              class="mb-1"
                              v-model="organisation.end.month"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              placeholder="Zoeken..."
                              :options="months"
                              :reduce="(item) => item.value"
                              :clearable="false"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  url,
  minLength,
  required,
  email,
  numeric,
} from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
    BAvatar,
    BOverlay,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BRow,
  BCol,
  BFormSpinbutton,
} from "bootstrap-vue";
import { auth, functions } from "@/firebase/firebaseConfig";

import mainMixin from '@/mixins/mainMixin';

import LogoText from "@core/layouts/components/LogoText.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import vSelect from "vue-select";

import { $themeColors } from "@themeConfig";

import Login from '@/views/authentication/Login.vue';

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "Enroll",
  components: {
      Login,
      //
    FormWizard,
    TabContent,
    // BSV
    BAvatar,
    BOverlay,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    LogoText,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BRow,
    BCol,
    BFormSpinbutton,
    vSelect,
  },
  mixins: [togglePasswordVisibility, mainMixin],
  data() {
    return {
        loading: false,
    color: $themeColors.primary,
      organisation: {
        fullName: null,
        shortName: null,
        mainEmail: null,
        website: null,
        address: {},
        type: "school",
        start: {
          month: 9,
          day: 1,
        },
        end: {
          month: 6,
          day: 30,
        },
      },
      error: null,
      months: [
        { label: "Januari", value: 1 },
        { label: "Februari", value: 2 },
        { label: "Maart", value: 3 },
        { label: "April", value: 4 },
        { label: "Mei", value: 5 },
        { label: "Juni", value: 6 },
        { label: "Juli", value: 7 },
        { label: "Augustus", value: 8 },
        { label: "September", value: 9 },
        { label: "Oktober", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
      ],
      max: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      types: [
        {
          label: "School",
          value: "school",
        },
        {
          label: "Jeugdbeweging",
          value: "scouts",
        },
        {
          label: "Voetbal",
          value: "football",
        },
        {
          label: "Volleybal",
          value: "volleybal",
        },
        {
          label: "Basketbal",
          value: "basketball",
        },
        {
          label: "Tennis",
          value: "tennis",
        },
        {
          label: "Golf",
          value: "golf",
        },
        {
          label: "Badminton",
          value: "badminton",
        },
        {
          label: "Atletiek",
          value: "atheltics",
        },
        {
          label: "Hockey",
          value: "hockey",
        },
        {
          label: "Wielrennen",
          value: "cycling",
        },
        {
          label: "Gevechtsport",
          value: "martialarts",
        },
        {
          label: "Dans",
          value: "Dance",
        },
        {
          label: "Kunst",
          value: "art",
        },
        {
          label: "Andere Sport",
          value: "othersport",
        },
        {
          label: "Andere",
          value: "other",
        },
      ],
    };
  },
  methods: {
    formSubmitted() {
      if (!this.$v.$invalid) {
        this.loading = true;
        functions
          .httpsCallable("createOrganisation")({
            organisation: this.organisation,
          })
          .then(async (res) => {
            setTimeout(async () => {
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Proficiat!',
                text: `${this.organisation.shortName} werd aangemaakt`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
              auth.currentUser.getIdToken(true);
              await this.$store.dispatch("auth/loadUser", {
                result: { user: auth.currentUser },
              });
              this.$store.dispatch("selectOrganisation", res.data.id);
              this.$router.push("/");
              this.$router.push("/");
              this.$router.push("/");
              this.loading = false;
            }, 5000);
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error!',
                text: error.message,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      } else this.$v.$touch();
    },
    validationGeneralForm(){
        this.$v.$touch();
        if(this.$v.organisation.fullName.$invalid ||this.$v.organisation.shortName.$invalid ||this.$v.organisation.website.$invalid ||this.$v.organisation.mainEmail.$invalid ||this.$v.organisation.address.street.$invalid ||this.$v.organisation.address.housenumber.$invalid ||this.$v.organisation.address.addition.$invalid ||this.$v.organisation.address.postal.$invalid ||this.$v.organisation.address.city.$invalid){
            return false
        }
        return true;
    },
    validationExtraForm(){
        this.$v.$touch();
        if(this.$v.organisation.start.month.$invalid ||this.$v.organisation.start.day.$invalid ||this.$v.organisation.end.month.$invalid ||this.$v.organisation.end.day.$invalid ||this.$v.organisation.type.$invalid){
            return false
        }
        return true;
    },
    checkAuth(){
        if(auth.currentUser != null){
            return true
        } else {
            return false
        }
    },
    changeAccount(){
        this.$store.dispatch('auth/signOut', false);
    },
  },
  created() {
    if (auth.currentUser != null) {
      this.currentStep = 1;
    }
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.currentStep = 1;
      }
    });
  },
  validations: {
    organisation: {
      fullName: {
        required,
      },
      shortName: {
        required,
      },
      website: {
        url,
      },
      mainEmail: {
        email,
      },
      type: {
        required,
      },
      address: {
        street: {
          required,
          minLength: minLength(3),
        },
        housenumber: {
          required,
        },
        addition: {},
        postal: {
          required,
        },
        city: {
          required,
        },
      },
      start: {
        day: {
          required,
          numeric,
        },
        month: {
          required,
          numeric,
        },
      },
      end: {
        day: {
          required,
          numeric,
        },
        month: {
          required,
          numeric,
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 50vw !important;
  min-width: 300px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import "@core/scss/vue/libs/vue-select.scss";
.vue-form-wizard{
    -webkit-box-shadow: 0px 0;
    box-shadow: 0px 0;
}
.vue-form-wizard .wizard-navigation .wizard-nav li:not(:first-child) a::before, .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: '→'
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: '←'
}
</style>